@font-face {
    font-family: 'Avenir-Light';
    src: local('Avenir-Light'), url(./Fonts/avenir/Avenir-Light.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Medium';
    src: local('Avenir-Medium'), url(./Fonts/avenir/Avenir-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: local('Avenir-Heavy'), url(./Fonts/avenir/Avenir-Heavy.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RockSalt-Regular';
    src: local('RockSalt-Regular'), url(./Fonts/rocksalt/RockSalt-Regular.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}