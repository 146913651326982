html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #151618;
  scroll-behavior: smooth;
  z-index: -2;
}

:root {
  --search-tab-panel-height: min(360px, calc(var(--vh, 1vh) * 45));
  --result-details-width: min(90vw, calc(var(--vh, 1vh) * 90));
  --result-details-title-height: max(60px, calc(var(--vh, 1vh) * 5));
  --results-gallery-height: max(320px, calc(var(--vh, 1vh) * 75 - 250px));
  --results-gallery-height-ratio: calc(var(--results-gallery-height) / 1.71 - calc(var(--vh, 1vh) * 3));
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  color: white;
  transform: rotate(180deg);
  font-family: 'Avenir-Heavy'
}

.App-header .is-hidden-desktop {
  font-size: 0.8em;
}

.App-bg {
  position: relative;
  width: 100vw;
  background-image: url('../bg_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform: rotate(180deg);
}


#root, #app-container {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

.no-hover {
    color: white !important;
}

.navbar .navbar-center {
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  width: 100%;
}

.centered {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.navbar {
  height: 70px;
}

.navbar .navbar-left {
  position: absolute;
}

.navbar-bottom {
  width: 100vw;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 1023px) {
  .navbar {
    display: flex;
    flex-wrap: wrap;
    height: 50px;
  }
  .navbar-brand {
    min-width: 100%;
  }
  .navbar-menu {
    margin-left: auto;
    min-width: 25%;
  }
}

.vertical-centered {
  display: flex;
  justify-content: center; 
  margin: auto;
}

.transparent {
  background: transparent;
}

.primary-text-color {
  color: #ffffff;
}

.primary-font-light {
  font-family: "Avenir-Light";
}
.primary-font-medium {
  font-family: "Avenir-Medium";
}
.primary-font-heavy {
  font-family: "Avenir-Heavy";
}

.secondary-font {
  font-family: "RockSalt-Regular";
}

.unclickable {
  pointer-events: none;
}

.is-full-width {
  width: 100%;
}

.not-vertically-scrollable {
  overflow-y: hidden;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.circle {
  border-radius: 50%;
}

.tag.is-medium {
  white-space: normal;
  height: 3.5em;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner-container {
  position: relative;
  margin: auto;
  width: 100px;
  height: 100px;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #fff;
  border-top-color: #00A1D4;
  animation: spinner .8s linear infinite;
}

.stopwatch-circle {
  border: 2px solid;
  width: 125px;
  height: 125px;
}

.chat-interface {
  position: fixed;
  top: 64px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.chat-messages {
  overflow-y: scroll;
  height: calc(100% - 225px);
}

.close-chat-interface {
  position: absolute;
  right: 10px;
  top: 0px;
}

.segment-position {
  position: absolute;
  right: 8px;
  top: 8px;
}

#mobile-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);
}


.search-page {
  min-height: calc(200vh - 70px);
  min-height: calc(calc(var(--vh, 1vh) * 200) - 70px);
}

.search-header {
  min-height: calc(200vh - 70px);
  min-height: calc(calc(var(--vh, 1vh) * 200) - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: 3em;
  color: white;
  font-family: 'Avenir-Heavy'
}

.search-header .is-hidden-desktop {
  font-size: 0.8em;
}

.search-bg {
  position: absolute;
  top:0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /*background-position: 50% 50%;*/
}

.search-tab-list {
    margin: 0;
    padding: 0;
    height: 65px;
    margin-top: 8vh;
    margin-top: calc(var(--vh, 1vh) * 8);
}

.search-tab {
  font-size: 16px;
  width: min(200px, 32vw);
  height: 40px;
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 0px 0px 0px 0;

  cursor: pointer;
  background-color: #e6e6e622;
  color: black;
  /*border-radius: 5px 5px 0 0;*/
  margin-right: 2px;

}

.search-tab p {
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-tab--selected {
    font-size: 16px;
    width: min(200px, 32vw);
    height: 40px;
    display: inline-block;
    position: relative;
    list-style: none;
    padding: 0px 0px 0px 0;

    cursor: pointer;
    margin-right: 2px;

    background-color: #ffffff44;
    color: white;
    z-index: 3 !important;
}

.search-tab--selected p {
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-tab-panel {
  border-radius: 0px 0px 20px 20px;
  background-color: #ffffff44;
  height: min(360px, 45vh); 
  height: var(--search-tab-panel-height); 
  font-size: 16px;
  padding: 10px;
  width: calc(min(600px, 96vw) + 4px);
  display: none;
  z-index: 2;
  position: relative;
}

.search-tab-panel--selected {
    display: block;
    z-index: 10;
}
.search-tab-panel--selected .search-any {
  display: block;
}

input[type="text"]::-webkit-input-placeholder {
  color: #a6a6a6;
}

.rbt-input-hint {
  color: white;
}

.rbt-input-main {
  color: white;
  font-size: 17px;
  font-family: "Avenir-Light";
}

.rbt-input-multi {
  background: transparent;
  width: 90%;
  border-radius: 20px;
  margin: auto;
  max-height: 150px;
  overflow-y:auto;
}

.rbt-input-multi.focus {
  box-shadow: none;
  border-color: white;
}

.dropdown-menu {
  color: white;
}

.rbt-token {
  border-radius: 50px;
  color: #FFFFFF;
}

.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  background: transparent;
  outline: none;
  border: none;
  padding: 4px 7px;
  width: 100%;
  position: absolute;
  border-radius: 50px;
  right: 0;
  text-shadow: none;
}

.rbt-token .rbt-token-remove-button span {
  position: absolute;
  justify-content: center;
  padding: 4px 7px;
  top: 0;
  bottom: 0;
  right: 0;
}

.rbt-token .sr-only {
  display: none;
}

.rbt-menu-custom-option {
  font-weight: bold;
  border-top: 1px solid gray;
}

.typeahead-genre .rbt-menu-custom-option .rbt-highlight-text {
  color: #D000D4;
}
.typeahead-genre .rbt-menu-custom-option.active  .rbt-highlight-text {
  color: white;
}
.typeahead-genre .rbt-menu-custom-option:active  .rbt-highlight-text {
  color: white;
}

.typeahead-author .rbt-menu-custom-option .rbt-highlight-text {
  color: #00A1D4;
}
.typeahead-author .rbt-menu-custom-option.active  .rbt-highlight-text {
  color: white;
}
.typeahead-author .rbt-menu-custom-option:active  .rbt-highlight-text {
  color: white;
}

.typeahead-keyword .rbt-menu-custom-option .rbt-highlight-text {
  color: #05A801;
}
.typeahead-keyword .rbt-menu-custom-option .rbt-highlight-text:active {
  color: white;
}
.typeahead-keyword .rbt-menu-custom-option.active  .rbt-highlight-text {
  color: white;
}
.typeahead-keyword .rbt-menu-custom-option:active  .rbt-highlight-text {
  color: white;
}

.typeahead-genre .rbt-token {
  background-color: #D000D4;
  border: 1px solid #D000D4;
}
.typeahead-genre .dropdown-item:active {
  background-color: #D000D4;
  border: 1px solid #D000D4;
}
.typeahead-genre .dropdown-item.active {
  background-color: #D000D4;
  border: 1px solid #D000D4;
}
.typeahead-genre-any .rbt-token {
  background-color: #D000D422;
  border: 1px solid #D000D4;
}
.typeahead-genre-any .dropdown-item:active {
  background-color: #D000D4;
  border: 1px solid #D000D4;
}
.typeahead-genre-any .dropdown-item.active {
  background-color: #D000D466;
  border: 1px solid #D000D4;
}



.typeahead-author .rbt-token {
  background-color: #00A1D4;
  border: 1px solid #00A1D4;
}
.typeahead-author .dropdown-item:active {
  background-color: #00A1D4;
  border: 1px solid #00A1D4;
  color: white;
}
.typeahead-author .dropdown-item.active {
  background-color: #00A1D4;
  border: 1px solid #00A1D4;
}
.typeahead-author-any .rbt-token {
  background-color: #00A1D422;
  border: 1px solid #00A1D4;
}
.typeahead-author-any .dropdown-item:active {
  background-color: #00A1D4;
  border: 1px solid #00A1D4;
}
.typeahead-author-any .dropdown-item.active {
  background-color: #00A1D488;
  border: 1px solid #00A1D4;
}



.typeahead-keyword .rbt-token {
  background-color: #05A801;
  border: 1px solid #05A801;
}
.typeahead-keyword .dropdown-item:active {
  background-color: #05A801;
  border: 1px solid #05A801;
}
.typeahead-keyword .dropdown-item.active {
  background-color: #05A801;
  border: 1px solid #05A801;
}
.typeahead-keyword-any .rbt-token {
  background-color: #05A80122;
  border: 1px solid #05A801;
}
.typeahead-keyword-any .dropdown-item:active {
  background-color: #05A801;
  border: 1px solid #05A801;
}
.typeahead-keyword-any .dropdown-item.active {
  background-color: #05A80188;
  border: 1px solid #05A801;
}



.suggestion {
  color: white;
  border-bottom:2px solid white;
  padding-bottom:1px;
}
.suggestion:hover {
  font-weight: bold;
  padding-bottom:2px;
  cursor: pointer;
}

.suggestion-genre:hover {
  color: #D000D4;
  border-bottom:2px solid #D000D4;
}
.suggestion-author:hover {
  color: #00A1D4;
  border-bottom:2px solid #00A1D4;
}
.suggestion-keyword:hover {
  color: #05A801;
  border-bottom:2px solid #05A801;
}

.search-tab-panel .search-any {
  visibility: hidden;
  pointer-events: none;
}
.search-tab-panel--selected .search-any {
  visibility: visible;
  pointer-events: all;
}
.search-any {
  padding-top: 20px;
  display: inline-block;
  font-size: 16px;
}
.switch-field {
  display: inline-block;
  /*width: 80px;*/
  margin-left: 5px;
  margin-right: 5px;
}

.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field label {
  background-color: transparent;
  border: 1px solid #969696;
  color: #969696;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
}

.switch-field label:first-of-type {
  border-right: none;
  padding: 3px 13px;
}
.switch-field label:last-of-type {
  border-left: none;
  padding: 3px 11px;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label:first-of-type {
  background-color: #000000;
  border: 2px solid #000000;
  padding: 2px 12px;
  color: #fff;
  font-weight: bold;
}
.switch-field input:checked + label:last-of-type {
  background-color: #00000066;
  border: 2px solid #000000;
  padding: 2px 10px;
  color: #fff;
  font-weight: bold;
}

.switch-field label:first-of-type {
  border-radius: 20px 0 0 20px;
}

.switch-field label:last-of-type {
  border-radius: 0 20px 20px 0;
}
.genre.switch-field input:checked + label:first-of-type  {
  background-color: #D000D4;
  border: 2px solid #D000D4; 
}
.genre.switch-field input:checked + label:last-of-type  {
  background-color: #D000D422;
  border: 2px solid #D000D4; 
}
.author.switch-field input:checked + label:first-of-type  {
  background-color: #00A1D4;
  border: 2px solid #00A1D4; 
}
.author.switch-field input:checked + label:last-of-type  {
  background-color: #00A1D422;
  border: 2px solid #00A1D4; 
}
.keyword.switch-field input:checked + label:first-of-type  {
  background-color: #05A801;
  border: 2px solid #05A801; 
}
.keyword.switch-field input:checked + label:last-of-type  {
  background-color: #05A80122;
  border: 2px solid #05A801; 
}


.title-circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  font-size: 12px;
  color: #767676;
  text-align: center;
  background: #000
}
.title-circle.any {
  background: #00000044;
  border: 1px solid #000
}

.search-tab--selected .title-circle.genre {
  background: #D000D4;
  color: #FFFFFF;
}
.search-tab--selected .title-circle.author {
  background: #00A1D4;
  color: #FFFFFF;
}
.search-tab--selected .title-circle.keyword {
  background: #05A801;
  color: #FFFFFF;
}
.search-tab--selected .title-circle.any.genre {
  background: #D000D444;
  border: 1px solid #D000D4;
  color: #FFFFFF;
}
.search-tab--selected .title-circle.any.author {
  background: #00A1D444;
  border: 1px solid #00A1D4;
  color: #FFFFFF;
}
.search-tab--selected .title-circle.any.keyword {
  background: #05A80144;
  border: 1px solid #05A801;
  color: #FFFFFF;
}

.search-button-disabled {
  position: absolute;
  bottom: 0;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 0%);
  margin-bottom: 20px;
  height: 50px;
  font-size: 16px;

  background: #444546;
  color: #8A8B8C;
  outline: none;
  border: none;
  pointer-events: none;
}

.search-button {
  position: absolute;
  bottom: 0;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 0%);
  margin-bottom: 20px;
  font-size: 20px;

  background: #151618;
  color: #444546;
  outline: none;
  border: none;
}

.search-button-genre:hover {
  background: #D000D4;
}
.search-button-author:hover {
  background: #00A1D4;
}
.search-button-keyword:hover {
  background: #05A801;
}

.search-filters {
  position: relative;
  display: flex-inline;
  font-size: 16px;
  width: min(800px, 96vw);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  overflow-y: scroll;
}


.search-filter {
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 50px;
  background-color: #e7f4ff;
  border: 0;
  display: inline-block;
  line-height: 1em;
  margin: 2px;
  padding: 4px 7px;
  position: relative;

  cursor: pointer;
  padding-right: 21px;

}

.search-filter .search-filter-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  background: transparent;
  outline: none;
  border: none;
  padding: 4px 7px;
  width: 100%;
  position: absolute;
  border-radius: 50px;
  right: 0;
  text-shadow: none;
}

.search-filter .search-filter-remove-button  span {
  position: relative;
  justify-content: center;
  float: right;
  pointer-events: none;
}

.search-filter .sr-only {
  display: none;
}


.search-filter.genres {
  background: #D000D4;
  border: 1px solid #D000D4;
}
.search-filter.genres-any {
  background: #D000D444;
  border: 1px solid #D000D4;
}

.search-filter.authors {
  background: #00A1D4;
  border: 1px solid #00A1D4;
}
.search-filter.authors-any {
  background: #00A1D444;
  border: 1px solid #00A1D4;
}

.search-filter.keywords {
  background: #05A801;
  border: 1px solid #05A801;
}
.search-filter.keywords-any {
  background: #05A80144;
  border: 1px solid #05A801;
}


.results-count {
  width: min(1100px, 96vw);
  position: relative;
  text-align: left;
  padding-left: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.results-gallery {
  position: absolute;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 0%);

  height: max(320px, calc(100vh - 250px - 25vh));
  height: var(--results-gallery-height);
  width: min(1100px, 96vw);
  background: #FFFFFF11;
  text-align: center;
  border-radius: 20px;

  padding-left: 10px;
  padding-right: 10px;

  overflow:scroll;
  overflow-y: hidden;
  white-space:nowrap;
}

.results {
  display: inline-flex;
  height: 100%;
}

.result-card {
  width: calc( max(320px, calc(75vh - 250px)) / 1.71 - 3vh);
  width: var(--results-gallery-height-ratio);
  margin: auto;
  cursor: pointer;
}

/* Position child elements relative to this element */
.result-image {
  position: relative;
  margin: 10px;
  margin-top: 0px;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.result-image::after {
  display: block;
  content: "";
  /* 16:9 aspect ratio */
  padding-bottom: 171.25%;
}

/* Image is positioned absolutely relative to the parent element */
.result-image img {
  /* Image should match parent box size */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.result-card p {
  bottom: 0;
  position: relative;
  display: block;
  height: 22px;
}

.result-title {
  font-size: 16px;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.result-subtitle {
  font-size: 14px;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.load-more {
  margin: auto;
  justify-content: center;
  box-shadow: 0;
  color: white;
  border: 1px white;
  font-size: 16px;
  background: transparent;
  position: relative;
  border-radius: 20px;
}

.load-more p {
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: 15px;
  margin-right: 20px;
}

.error-message {
  font-size: 30px;
}

.result-details-bg {
  position: absolute;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  transform: scaleY(-1);
  /*background-position: 50% 50%;*/
}

.about-top-bg {
  position: absolute;
  top:0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /*background-position: 50% 50%;*/
}

.about-bottom-bg {
  position: absolute;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  transform: scaleY(-1);
  /*background-position: 50% 50%;*/
}

@media screen and (max-width: 1023px) {
  .result-details-bg {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  }
}

.result-details-container {
  position: absolute;
  top: 100vh;
  top: calc(var(--vh, 1vh) * 100);
  left: 0;
  height: calc(100vh - 72px);
  height: calc(calc(var(--vh, 1vh) * 100) - 72px);
  width: 100vw;
}

.back-to-results {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: 60px;
  width: 200px;
  cursor: pointer;
}

.back-to-results img {
  margin: auto;
}

.next-result {
  position: absolute;
  top: 150vh;
  top: calc(var(--vh, 1vh) * 150);
  height: 50px;
  width: 50px;
  right: calc(calc(100vw - min(calc(60vh * 1.5), 90vw)) * 0.25); /* position the left edge of the element at the middle of the parent */
  right: calc(calc(100vw - var(--result-details-width)) * 0.25); /* position the left edge of the element at the middle of the parent */
  z-index: 10;
  transform: translateY(-100%);
  cursor: pointer;
}
.previous-result {
  position: absolute;
  top: 150vh;
  top: calc(var(--vh, 1vh) * 150);
  height: 50px;
  width: 50px;
  left: calc(calc(100vw - min(calc(60vh * 1.5), 90vw)) * 0.25);
  left: calc(calc(100vw - var(--result-details-width)) * 0.25);
  z-index: 10;
  transform: translateY(-100%);
  cursor: pointer;
}

.result-details {
  position: relative;
  margin: auto;
  width: min(calc(60vh * 1.5), 90vw);
  width: var(--result-details-width);
  height: calc(100vh - 132px);
  height: calc(calc(var(--vh, 1vh) * 100) - 132px);
  /*background: red;*/
}

.result-details-spacer {
  position: relative;
  display: block;
  width: 100%;
  height: 20px;
  background: transparent;
}
.result-details-title {
  position: relative;
  display: flex;
  width: 100vw;
  height: max(5vh, 60px);
  height: var(--result-details-title-height);
  font-size: 25px;
  align-items: center;
  justify-content: center;
  /*background: white;*/

}

.result-details-book-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} 

.result-details-author {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.result-details-body {
  height: calc(100% - 60px);
}
.result-details-left {
  position: relative;
  display: inline-flex;
  font-size: 0;
  width: calc( 60vh / 1.7125);
  width: calc( calc(var(--vh, 1vh) * 60) / 1.7125);
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  /*background: blue;*/


}
.result-details-card {
  /*height: 100%;*/
  margin-top: calc(0.5 * (100vh - 132px - 1 * max(5vh, 60px) - 60vh));
  margin-top: calc(0.5 * (calc(var(--vh, 1vh) * 40) - 132px - var(--result-details-title-height)));
  width: calc( 60vh / 1.7125);
  width: calc( calc(var(--vh, 1vh) * 60) / 1.7125);
  border-radius: 20px;
}
.result-details-right {
  position: relative;
  display: inline-flex;
  font-size: 0;
  vertical-align: top;
  width: calc(100% - 60vh / 1.7125);
  width: calc(100% - calc(var(--vh, 1vh) * 60) / 1.7125);
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  padding-left: 20px;
  /*background: gray;*/
}
/* Position child elements relative to this element */
.result-details-image {
  position: relative;
  pointer-events: none;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.result-details-image::after {
  display: block;
  content: "";
  /* 16:9 aspect ratio */
  padding-bottom: 171.25%;
}

/* Image is positioned absolutely relative to the parent element */
.result-details-image img {
  /* Image should match parent box size */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
  border-radius: 20px;
}

.result-details-purchase {
  width: 100%;
  display: flex;
  height: max(5vh, 60px);
  height: var(--result-details-title-height);
  /*background: yellow;*/
  font-size: 0;
  align-items: center;
  justify-content: center;
}

.details-container {
  text-align: center;
  margin-bottom: 10px;
}
.details-genre {
  display: inline-block;
  background-color: #D000D4;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  color: white;
  margin: 2px;
  font-size: 16px;
}
.details-author {
  display: inline-block;
  background-color: #00A1D4;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  color: white;
  margin: 2px;
  font-size: 16px;
}
.details-keyword {
  font-weight: bold;
  color: #05A801;
}

.small-desktop {
  visibility: hidden;
}

.results-details-tabs {
  margin-top: calc(0.5 * (100vh - 132px - 1 * max(5vh, 60px) - 60vh));
  margin-top: calc(0.5 * (calc(var(--vh, 1vh) * 40) - 132px - var(--result-details-title-height)));
  text-align: left;
  width: calc(min(calc(60vh * 1.5), 90vw) - 60vh / 1.7125);
  width: calc(var(--result-details-width) - calc(var(--vh, 1vh) * 60) / 1.7125);
  /*justify-content: center;*/
}

.results-tab {
  font-size: 16px;
  width: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 0px 0px 0px 0;

  cursor: pointer;
  background-color: transparent;
  color: #767676;
  border: 1px solid #767676;
  /*border-radius: 5px 5px 0 0;*/

}

.results-tab p {
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
}

.results-tab--selected {
  font-size: 16px;
  width: 50%;
  height: 40px;
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 0px 0px 0px 0;

  cursor: pointer;

  background-color: white !important;
  color: black;
  z-index: 3 !important;
  border: none;
}

.results-tab--selected p {
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
  
}

.results-tab-panel {
  border-radius: 0px 0px 20px 20px;
  height: calc(60vh - 40px);
  height: calc(calc(var(--vh, 1vh) * 60) - 40px);
  font-size: 16px;
  padding: 10px;
  width: 100%;
  display: none;
  z-index: 2;
  position: relative;
}

.results-tab-panel-text {
  /*width: inherit;*/
  padding: 10px;
  height: calc(100% - 60px);
  overflow: scroll;
}

.centered-loader {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 0);
}

.results-tab-panel--selected {
  display: block;
  z-index: 10;
  width: 100%;
}

.purchase-button {
  position: absolute;
  bottom: 0;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 0%);
}

.purchase-button.bookshop {
  background-color: #6248A9;
}
.purchase-button.bookshop:hover {
  background-color: #573BA3;
}

.purchase-button.amazon {
  background-color: #545454
}
.purchase-button.amazon:hover {
  background-color: #484848;
}

.bookshop-logo {
  height: 20px;
  width:  20px;
  margin-left: 10px;
}
.amazon-logo {
  height: 20px;
  width:  20px;
  margin-left: 10px;
}

.result-details-title-mobile {
  visibility: hidden;
  display: none;
}

.middle-scroll {
  position: absolute;
  overflow: scroll;
  margin: auto;
  margin-top: 17vh;
  margin-top: calc(var(--vh, 1vh) * 17);
  margin-bottom: 12vh;
  margin-bottom: calc(var(--vh, 1vh) * 12);
  height: 71vh;
  height: calc(var(--vh, 1vh) * 71);
  width: 100vw;
  color: white;
}

.middle-scroll .title {
  font-size: 40px;
  color: white;
  margin-top: 10px;
}

.middle-scroll .subtitle {
  font-size: 25px;
  color: white;
}

.middle-scroll .tldr {
  text-align: left;
  color: white;
  width: 75%;
  margin: auto;
}

.bullet-list {
  width: 90%;
  margin: auto;
}

.bullet { 
  font-size: 20px;
  display:list-item;
}

.link {
  color: white;
  font-weight: bold;
}

.link:hover {
  color: #00A1D4;
}

.middle-bg {
  position: absolute;
  top:0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /*background-position: 50% 50%;*/
}


ul {
  list-style-position: outside;
}

.blog-page {
  min-height: calc(200vh - 140px);
  min-height: calc(calc(var(--vh, 1vh) * 200) - 140px);
  z-index: 0;
}

.blog-upper-bg {
  position: absolute;
  top:0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25)), url('../bg_blog_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: scaleX(-1);
  z-index: 0;
}

.blog-lower-bg {
  position: absolute;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25)), url('../bg_blog_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(-1, -1);
  z-index: 0;
}

.blog-intro {
  padding-top: 10vh;
  padding-top: calc(var(--vh, 1vh) * 10);
  height: 40vh;
  height: calc(var(--vh, 1vh) * 40);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.blog-intro p {
  text-align: center;
  font-size: 40px;
  color: white;
  z-index: 2;
}
.blog-intro a {
  text-align: center;
}
.blog-articles {
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
}
.blog-resources {
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
}

.blog-gallery-title {
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
  width: 80%;
  display: flex;
}

.blog-gallery-title p {
  font-size: 30px;
  color: white;
  z-index: 10;
  height: 100%
}

.blog-gallery {
  width: 80%;
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
  display: inline-flex;
  text-align: center;
  
  overflow:scroll;
  overflow-y: hidden;
  white-space:nowrap;

  border-radius: 20px;
  background: #00000099;
}

.blog-cards {
  background: #00000099;
  border-radius: 20px;
  display: inline-flex;
  margin: auto;
  height: 100%;
}


.blog-card {
  /*height: inherit;*/
  width: calc( 60vh / 1.71 - 3vh);
  width: calc( calc(var(--vh, 1vh) * 60) / 1.71 - calc(var(--vh, 1vh) * 3));
  /*margin: auto;*/
  margin-top: 20px;
  cursor: pointer;
}

.blog-card:hover {
  transition: transform .25s ease;
  transform: scale(1.02);
  color: #00A1D4;
}

/* Position child elements relative to this element */
.blog-image {
  position: relative;
  margin: 20px;
  margin-top: 0px;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.blog-image::after {
  display: block;
  content: "";
  /* 16:9 aspect ratio */
  padding-bottom: 171.25%;
}

/* Image is positioned absolutely relative to the parent element */
.blog-image img {
  /* Image should match parent box size */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card p {
  bottom: 0;
  position: relative;
  display: block;
  height: 22px;
}

.blog-spacer {
  height: 12vh;
  height: calc(var(--vh, 1vh) * 12);
  width: 100vw;
}

.blog-title {
  font-size: 16px;
  color: white;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.resource-intro {
  width: min(1000px, 95vw);
  position: relative;
  margin: auto;
  margin-bottom: 20px;
  color: white;
  text-align: left;
}
.resource-intro .title {
  text-align: center;
  font-size: 35px;
  color: white;
  margin-top: 10px;
}

.resource-table {
  position: absolute;
  top: 50px;
  z-index: 1;
  color: black;
  margin: auto;
  width: 100%;
  height: 90vh;
  height: calc(var(--vh, 1vh) * 90);
  padding: 1rem;
  display: block;
}

.resource-table table {
  display: block;
  overflow: auto;
  text-align: center;
  position: relative;
  margin: auto;
  
  height: calc(100vh - 270px);
  height: calc(calc(var(--vh, 1vh) * 100) - 270px);
  width: min(1000px, 95vw);

  border-collapse: separate;
  border-spacing: 0;
  border-radius: 20px;
}

.resource-table table thead tr th {
  background-color: #737374;
  position: sticky;
  top: 0px;
  border-right: 1px solid #FFFFFF55;

}


.resource-table table thead tr th {
  width: min(1000px, 95vw);
  padding-left: 15px;
  padding-right: 15px;
}
.resource-table table tr th:first-child {
  /*border-radius: 20px 0 0 0;*/
}
.resource-table table tr th:last-child {
  /*border-radius: 0 20px 0 0;*/
  border-right: 0;
}


.resource-table table tbody {
  /*display: block;*/
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  width: min(1000px, 95vw);
  /*border-radius: 0 0 20px 20px;*/
}



.resource-table table tr:last-child td {
  border-bottom: 0;
}

.resource-table table th, td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #FFFFFF55;
  border-right: 1px solid #FFFFFF55;
  background-color: #ffffff44;
  color: white;
}

.resource-table table th, td:last-child {
  border-right: 0;
}

.resource-table table tbody tr td {
  background-color: #35363711;
  vertical-align: middle; 
}

.resource-table table tbody tr:nth-child(odd) {
  background-color: #2B2B2B;
}
.resource-table table tbody tr:nth-child(even) {
  background-color: #0B0B0B;
}


.resource-table table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 20px;
}
.resource-table table tbody tr:last-child td:last-child {
  border-radius: 0 0 20px 0;
}



.resource-genre {
  display: inline-block;
  background-color: #D000D4;
  padding: 4px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  color: white;
  margin: 2px;
  font-size: 12px;
}
.resource-author {
  display: inline-block;
  background-color: #00A1D4;
  padding: 4px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  margin: 2px;
  font-size: 12px;
  color: white;
}
.resource-social {
  display: inline-block;
  color: white;
}
.resource-social svg {
  vertical-align: middle;
}
.facebook:hover path {
  fill: #3b5998;
}
.twitter:hover path {
  fill: #00ACEE;
}
.instagram:hover path {
  fill: url(#rg);
}

.resource-bg {
  position: absolute;
  top:0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /*background-position: 50% 50%;*/
}


.resource-search-bar {
  position: relative;
}

.resource-search-bar:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 30px;
  stroke: white;
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' width='15' height='15'%3E%3Cpath d='M14.5 14.5l-4-4m-4 2a6 6 0 110-12 6 6 0 010 12z' stroke='%23A6A6A6'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
}


.resource-input {
  width: min(1000px, 95vw);
  font-size: 20px;
  border-radius: 20px;
  border: none;
  height: 40px;
  text-align:center;
  margin-bottom: 10px;
  background-color: #0B0B0B;
  border:  1px solid #FFFFFF55;
  color: white;
  padding: 10px 0px;
}

.resource-input:focus{
  outline: none
}

.resource-input::placeholder {
  color: #A6A6A6;
}

.about-intro {
  position: absolute;
  top: 70px;
  color: white;
  text-align: center;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 0%);
  width: min(900px, 95vw);
  height: calc(100vh - 70px);
  height: calc(calc(var(--vh, 1vh) * 100) - 70px);
  overflow: scroll;
}
.about-intro .title {
  color: white;
}
.about-inner {
  overflow: scroll;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%,-50%);
}
.about-circle {
  margin: auto;
  width: 100px;
  height: 100px;
  background-image: url('../pr_flag.png');
  border-radius: 50%;
  background-size: contain;

}

.about-link {
  color: #88D3EB;
  font-weight: bold;
}

.about-link:hover {
  color: #00A1D4;
  font-weight: bold;
}


.faq {
  border-radius: 50px;
  height: calc(100vh - 10vh - 70px);
  height: calc(calc(var(--vh, 1vh) * 100) - calc(var(--vh, 1vh) * 10) - 70px);
  background-color: #ffffff44;
  overflow: scroll;
  width: min(1000px, 95vw);
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 0%);
  position: absolute;
  top: 105vh;
  top: calc(var(--vh, 1vh) * 105);
}
.faq .title {
  color: white;
  font-size: 20px;
}
.faq p {
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  text-align: left;
}

.email {
  font-weight: bold;
}

.navbar-burger.is-active{
  background-color: black;
}
.navbar-burger.is-active:after{
    content:'';
    width:10px;
    height:60px;
    border:5px solid black;
    position:absolute;
    bottom:-5px;
    border-top:0;
}
.navbar-burger.is-active:after{
    border-left:0;
    border-radius:0 0 20px 0;
    left:-5px;
}


.navbar-menu {
  border-radius: 20px 0 0 20px;
}

.popup-box {
  position: fixed;
  background: #00000077;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
}
 
.box {
  position: absolute;
  width: calc(min(400px, 90vw));
  top: calc(8vh + 87px + min(360px, 45vh));
  top: calc(calc(var(--vh, 1vh) * 8) + 87px + var(--search-tab-panel-height) );
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 60px;
  height: 50px;
  padding: 2px;
  padding-top: 4px;
  background: #fff;
  overflow: hidden;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box b {
  font-size: 16px;
  margin-left: -15px;
}
.box p {
  margin-left: -15px;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);

  /*background: #EBA088;*/
  background: #d43300;
  width: 30px;
  height: 60px;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  line-height: 55px;
  text-align: center;
  color: white;
  font-weight: normal;
  border: 1px solid #d43300;
  font-size: 30px;
  z-index: 10000;
}

.close-icon:hover {
  background: #9C2500;
  border: 1px solid #9C2500;
}





@media screen and (max-width: 1023px) {
  .previous-result {
    top: calc(100vh + 100px);
    top: calc(calc(var(--vh, 1vh) * 100) + 100px);
  }
  .next-result {
    top: calc(100vh + 100px);
    top: calc(calc(var(--vh, 1vh) * 100) + 100px);
  }
  .no-mobile {
    display: none;
  }
  .small-desktop {
    visibility: none;
  }
  .result-details-title-mobile {
    width: 80%;
    visibility: visible;
    display: block;
    z-index: 1;
    position: absolute;
    top: -5px;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 18px;
  }
}

@media screen and (max-width: 414px) {

  .mobile-font {
    font-size: 12px;
  }
  .search-tab {
    background-color: #252626;
  }
  .search-tab--selected {
    background-color: #626263ee;
  }
  .search-tab-panel {
    background-color: #626263ee; 
  }
  .result-details-title-mobile {
    width: 95%;
    visibility: visible;
    display: block;
    z-index: 1;
    position: absolute;
    top: 50px;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 18px;
  }
  .result-details-book-title {
    width: 90%;
  }
  .result-details-author {
    width: 80%;
  }
  .result-details-container {
    top: 100vh;
    top: calc(var(--vh, 1vh) * 100);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  .result-details {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }
  .result-details-body {
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  .result-details-spacer {
    height: 0;
  }
  .no-mobile {
    display: none;
  }
  .small-desktop {
    visibility: hidden;
  }
  .back-to-results {
    position: absolute;
    top: 0;
    height: 50px;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);
    z-index: 10;
  }
  .result-details-right {
    width: 100%;
    padding: 0;
    margin: auto;
  }
  .result-details-left {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px;
  }
  .result-details-image {
    position: relative;
    z-index: 0;
    border-radius: 0px;
  }

  /* Create a pseudo element that uses padding-bottom to take up space */
  .result-details-image::after {
    display: block;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  /* Image is positioned absolutely relative to the parent element */
  .result-details-image img {
    /* Image should match parent box size */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
    border-radius: 0px;

  }
  .result-details-bg {
    background-image: none;
  }
  .result-details-card {
    border-radius: 0px;
    width: 100%;
    margin: 0;
    background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    mix-blend-mode: overlay;
  }
  .results-details-tabs {
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    margin-top: 130px;
    height: calc(100vh - 130px);
    height: calc(calc(var(--vh, 1vh) * 100) - 130px);
    width: 100%;
  }
  .results-tab-panel {
    width: 100vw;
    height: calc(100vh - 200px);
    height: calc(calc(var(--vh, 1vh) * 100) - 200px);

  }
  .results-tab-panel--selected {
    width: 100vw;
    height: calc(100vh - 200px);
    height: calc(calc(var(--vh, 1vh) * 100) - 200px);
  }
  .results-tab-panel-text {
    margin: 0px;
    padding: 10px;
    position: relative;
    background:  #2d2d2ddd;
    border-radius: 20px;
    border:  2px solid #5d5d5d;
    color: white;
  }
  .results-tab-list {
    margin-left: 10px;
    margin-right: 10px;
  }
  .purchase-button  {
    display: flex;
    align-items: flex-end;
  }

  .results-gallery {
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 0%);

    height: calc(100vh - 205px - 2vw);
    height: calc(calc(var(--vh, 1vh) * 100) - 205px - 2vw);
    width: 96vw;
    background: #00000099;
    text-align: center;
    border-radius: 20px;

    padding-left: 10px;
    padding-right: 10px;

    overflow: auto;
    white-space:nowrap;
  }
  .results {
    display: block;
    height: 100%;
  }
  .result-card {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .search-tab {
    font-size: 12px;
    height: 30px;
  }
  .search-tab--selected {
    font-size: 12px;
    height: 30px;
  }
  .search-tab-list {
    height: 55px;
    margin-top: -20px;
  }
  .search-tab-panel {
    height: calc(100vh - 97px);
    height: calc(calc(var(--vh, 1vh) * 100) - 97px);
    padding: 0px;
    font-size: 16px;
    margin-top: 6px;
  }
  .title-circle {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }
  .search-filters {
    height: 54px;
    line-height: 0.6em;
    margin-top: 10px;
  }
  .search-filter {
    font-size: 14px;
    margin: 2px;
  }
  .search-filter .search-filter-remove-button {
    padding: 3px 7px;
  }
  .results-count {
    width: min(1100px, 96vw);
    position: relative;
    text-align: center;
    padding-right: 8px;
    padding-top: 8px;
    font-size: 12px;
    z-index: 10;
  }
  .result-title {
    position: absolute;
  }
  .blog-page {
    min-height: calc(200vh - 52px);
    min-height: calc(calc(var(--vh, 1vh) * 200) - 52px);
  }
  .blog-intro {
    padding-top: 0vh;
    padding-top: calc(var(--vh, 1vh) * 0);
  }
  .blog-intro p {
    font-size: 25px;
  }
  .blog-gallery-title p {
    font-size: 20px;
  }

  .about-circle {
    width: 50px;
    height: 50px;
  }
  .search-any {
    font-size: 12px;
  }
  .switch-field label {
    font-size: 10px;
  }
  .switch-field label:first-of-type {
    padding: 3px 7px;
  }
  .switch-field label:last-of-type {
    padding: 3px 5px;
  }
  .switch-field input:checked + label:first-of-type {
    padding: 2px 6px;
  }
  .switch-field input:checked + label:last-of-type {
    padding: 2px 4px;
  }
  .no-results {
    vertical-align: middle;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  .end-results {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .end-results div {

  }
  .loader {
    vertical-align: middle;
    justify-content: center;
    position: absolute;
    top:  50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .load-more {
    height: 100%;
  }
  .load-more p {
    border-radius: 20px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
  }

  .resource-intro {
    width: 100%;
    position: relative;
    margin: auto;
    margin-bottom: 20px;
    color: white;
    text-align: left;
  }
  .resource-intro .title {
    text-align: center;
    font-size: 35px;
    color: white;
    margin-top: 10px;
  }

  .resource-table {
    top: 30px;
    font-size: 12px;
  }
  .resource-table table {
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    width: 100%;
  }

  .resource-table table thead tr th {
     width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .resource-table table tbody {
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    width: 100%;
  }
  .resource-input {
    width: 100%;
    font-size: 16px;
    height: 30px;
  }
  .resource-intro .title {
    text-align: center;
    font-size: 20px;
    color: white;
    margin-top: 0px;
    visibility: hidden;
    height: 0;
  }
  .search-button-disabled {
    font-size: 14px;
  }
  .middle-scroll .title {
    font-size: 30px;
    margin-top: 10px;
  }
  .middle-scroll .subtitle {
    font-size: 16px;
  }
  .middle-scroll .tldr {
    width: 90%;
    font-size: 14px;
  }
  .bullet-list {
    width: 80%;
  }
  .bullet {
    font-size: 14px;
  }
  .no-desktop {
    display: none;
  }
  .about-intro {
    height: 100%;
    position: relative;
    max-height: none;
    top: 0px;
    padding-top:  10px;
    background-color: transparent;
    overflow: auto;
  }
  .about-intro .title {
    color: white;
  }
  .about-inner {
    top:  0;
    left:  0;
    transform: none;
    max-height: none;
    overflow: auto;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
  }
  .faq {
    height: 100%;
    position: relative;
    max-height: none;
    overflow: auto;
    background: transparent;
    top: 0;
  }
  .faq .title {
    padding-top: 5px;
  }
  .faq p {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobile-bg {
    width: 100vw;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.8)), url('../bg_combo_1200.jpg');
    background-repeat: repeat-y;
  }
  .middle-bg {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url('../bg_search_1200.jpg');
  }
  .resource-bg {
    position: absolute;
    top:0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url('../bg_combo_1200.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    /*background-position: 50% 50%;*/
  }
  .box {
    top: calc(100vh - 50px);
    top: calc(calc(var(--vh, 1vh) * 100) - 50px);
  }
}

