

$primary-text-color: #FFFFFF;
$primary-highlight-color: #00A1D4;
$primary-highlight-color-dark: #0091BF;
$secondary-highlight-color: #88D3EB;

$navbar-item-background-color: $primary-highlight-color;
$navbar-item-hover-color: $primary-highlight-color;
$navbar-item-hover-background-color: transparent;
$navbar-dropdown-background-color: $primary-highlight-color;
$navbar-background-color: #000000;

// Import only what you need from Bulma
$primary: #00A1D4;
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
@import '../node_modules/bulma/sass/layout/all.sass';

@import '../node_modules/bulma/sass/components/breadcrumb.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/components/level.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/tabs.sass';

@import '../node_modules/bulma/sass/elements/box.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/content.sass';
@import '../node_modules/bulma/sass/elements/notification.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/elements/tag.sass';

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/_dropdown';

